<template>
	<v-layout>
		<v-flex xs12>
			<GivingFormBreadCrumbs v-if="givingFormState.givingForm.formId"></GivingFormBreadCrumbs>
			<component :is="currentGivingFormStep" v-if="givingFormLoaded && givingFormsLoaded" ref="currentFormStep"></component>
			<div class="text-right">
				<r-btn v-if="!prevPage && isNewGivingForm" :to="{ name: 'giving-forms' }" color="default">Cancel</r-btn>
				<r-btn v-if="prevPage && isNewGivingForm" class="ml-2" :to="{ name: 'giving-form', params: { page: prevPage, formId: givingFormState.givingForm.formId }, query: { isNew: true } }" color="default">
					Back
				</r-btn>
				<r-btn v-if="prevPage && !isNewGivingForm" class="ml-2" :to="{ name: 'giving-form', params: { page: prevPage, formId: givingFormState.givingForm.formId } }" color="default">Cancel</r-btn>
				<r-btn v-if="!showSaveButton && nextPage" class="ml-2" @click="goToNextPage">Save & Continue</r-btn>
				<r-btn v-if="showSaveButton" class="ml-2" @click="saveAndExitGivingForm">
					Save
				</r-btn>
			</div>
		</v-flex>
	</v-layout>
</template>

<script>
import GeneralUse from '@/components/giving/givingForms/GeneralUse.vue';
import GeneralSettings from '@/components/giving/givingForms/GeneralSettings.vue';
import Design from '@/components/giving/givingForms/Design.vue';
import ThankYouMessage from '@/components/giving/givingForms/ThankYouMessage.vue';
import GivingFormBreadCrumbs from '@/components/giving/givingForms/GivingFormBreadCrumbs.vue';

export default {
	components: {
		GeneralSettings,
		Design,
		ThankYouMessage,
		GivingFormBreadCrumbs,
		GeneralUse
	},
	data() {
		return {
			givingFormState: _REALM.STORE.givingFormStore.state,
			fundState: _REALM.STORE.fundStore.state,
			currentPage: _REALM.VM.$route.params.page,
			givingFormsLoaded: false,
			givingFormLoaded: false
		};
	},
	computed: {
		currentGivingFormStep() {
			if (this.currentPage === 'general-use') {
				return 'GeneralUse';
			} else if (this.currentPage === 'general-settings') {
				return 'GeneralSettings';
			} else if (this.currentPage === 'design') {
				return 'Design';
			} else if (this.currentPage === 'thank-you-message') {
				return 'ThankYouMessage';
			}

			return 'GeneralUse';
		},
		nextPage() {
			if (this.currentPage === 'general-use') {
				return null;
			} else if (this.currentPage === 'general-settings') {
				return 'design';
			} else if (this.currentPage === 'design') {
				return 'thank-you-message';
			} else if (this.currentPage === 'thank-you-message') {
				return null;
			}

			return null;
		},
		prevPage() {
			if (this.currentPage === 'general-use') {
				return null;
			} else if (this.currentPage === 'general-settings') {
				return _REALM.VM.$route.query.isNew ? null : 'general-use';
			} else if (this.currentPage === 'design') {
				return _REALM.VM.$route.query.isNew ? 'general-settings' : 'general-use';
			} else if (this.currentPage === 'thank-you-message') {
				return _REALM.VM.$route.query.isNew ? 'design' : 'general-use';
			}

			return null;
		},
		showSaveButton() {
			return (_REALM.VM.$route.params.formId && this.currentPage !== 'general-use' && !_REALM.VM.$route.query.isNew) || this.currentPage === 'thank-you-message';
		},
		isNewGivingForm() {
			return _REALM.VM.$route.query.isNew;
		}
	},
	created() {
		var self = this;
		_REALM.STORE.givingFormStore.listGivingForms(_REALM.STORE.siteStore.state.site.siteId).then(function() {
			self.givingFormsLoaded = true;
		});

		if (_REALM.VM.$route.params.formId && !_REALM.STORE.givingFormStore.state.givingForm.formId) {
			_REALM.STORE.givingFormStore.getGivingForm(_REALM.VM.$route.params.formId).then(function() {
				_REALM.STORE.memorialStore.buildSelectableGivingFormMemorials();
				self.givingFormLoaded = true;
			});
		} else {
			_REALM.STORE.givingFormStore.clearCurrentGivingForm();
			self.givingFormLoaded = true;
		}
	},
	destroyed() {
		_REALM.STORE.givingFormStore.clearCurrentGivingForm();
	},
	methods: {
		goToNextPage() {
			var self = this;
			if (self.$refs.currentFormStep.validate()) {
				self.saveGivingForm().then(function(givingForm) {
					_REALM.VM.$router.push({
						name: 'giving-form',
						params: {
							page: self.nextPage,
							formId: givingForm.formId
						},
						query: {
							isNew: true
						}
					});
				});
			}
		},
		saveAndExitGivingForm() {
			var self = this;
			if (self.$refs.currentFormStep.validate()) {
				if (self.givingFormState.givingForm.formId) {
					self.saveGivingForm().then(function(givingForm) {
						self.afterAddOrSave(givingForm, 'update');
					});
				} else {
					self.saveGivingForm().then(function(givingForm) {
						self.afterAddOrSave(givingForm, 'add');
					});
				}
			}
		},
		saveGivingForm() {
			if (this.givingFormState.givingForm.formId) {
				return this.updateGivingForm();
			} else {
				return this.addGivingForm();
			}
		},
		afterAddOrSave(givingForm, type) {
			_REALM.STORAGE.set('givingFormsIntroDismissed', true);
			_REALM.VM.$router.push({
				name: 'giving-form',
				params: {
					page: 'general-use',
					formId: givingForm.formId
				}
			});

			_REALM.TOAST.success(`${this.givingFormState.givingForm.name} has been ${type === 'add' ? 'added' : 'saved'}.`);
		},
		addGivingForm() {
			return _REALM.STORE.givingFormStore.addGivingForm(this.givingFormState.givingForm);
		},
		updateGivingForm() {
			return _REALM.STORE.givingFormStore.updateGivingForm(this.givingFormState.givingForm);
		}
	}
};
</script>

<template>
	<v-layout>
		<v-flex xs12>
			<r-banner :showBannerOnInit="givingFormState.givingForms.length === 0" class="mb-4">
				<template slot="icon">
					feedback
				</template>
				<template slot="title">
					Tell us about your giving form.
				</template>
				<template slot="text">
					Give this form a name that's easy to remember and defines what contributors will give toward. Your contributors will never see the form's name. Set this form as your default if it promotes your most important needs or if it contains a list of general funds.
				</template>
			</r-banner>

			<r-card class="mb-4">
				<template slot="title">
					General Settings
				</template>
				<OptionsForm ref="optionsFormRef"></OptionsForm>
			</r-card>

			<r-card v-if="memorialStore.state.editingSortableMemorials" class="my-4">
				<template slot="title">
					Included In Memory / In Honor
				</template>
				<p>Select any In Memory / In Honor that you want to include on this giving form. You can reorder them according to priority.</p>
				<v-layout wrap>
					<v-flex xs8>
						<r-sortable-list v-model="memorialStore.state.sortableMemorials"></r-sortable-list>
					</v-flex>
				</v-layout>
			</r-card>

			<r-card class="my-4">
				<template slot="title">
					Included Funds
				</template>
				<p>Select the funds you want to include on this giving form. You can reorder them according to priority.</p>
				<v-layout wrap>
					<v-flex xs8>
						<IncludedFundsList ref="includedFundsList"></IncludedFundsList>
					</v-flex>
				</v-layout>
			</r-card>
		</v-flex>
	</v-layout>
</template>

<script>
import IncludedFundsList from '@/components/giving/givingForms/IncludedFundsList.vue';
import OptionsForm from '@/components/giving/givingForms/OptionsForm.vue';
import _each from 'lodash/each';

export default {
	components: {
		IncludedFundsList,
		OptionsForm
	},
	data() {
		return {
			givingFormState: _REALM.STORE.givingFormStore.state,
			memorialStore: _REALM.STORE.memorialStore
		};
	},
	created() {
		this.initMemorialSettings();
	},
	methods: {
		initMemorialSettings() {
			this.memorialStore.buildSortableMemorials();
			this.memorialStore.state.editingSortableMemorials = this.memorialStore.siteHasMemorials() && this.givingFormState.givingForm.memorials !== null && this.memorialStore.state.sortableMemorials.some(sm => sm._isChecked);
		},
		validate() {
			return this.$refs.optionsFormRef.validateForm() && this.validateSelectedFunds() && this.validateSelectedMemorials();
		},
		validateSelectedFunds() {
			var fundList = [];
			_each(this.givingFormState.sortableFunds, function(fund) {
				if (fund._isChecked) {
					fundList.push({
						fundId: fund.fundId,
						isMemoEnabled: fund._isMemoEnabled,
						predefinedMemos: fund._predefinedMemos
					});
				}
			});

			_REALM.STORE.givingFormStore.state.givingForm.funds = fundList;

			if (this.givingFormState.givingForm.funds.length === 0) {
				_REALM.TOAST.error('You must select at least one fund.');
				return false;
			}

			return true;
		},
		validateSelectedMemorials() {
			if (this.memorialStore.state.editingSortableMemorials) {
				const selectedMemorials = this.memorialStore.getCheckedMemorialIds();
				if (selectedMemorials.length === 0) {
					_REALM.TOAST.error('You must select at least one In Memory / In Honor option.');
					return false;
				}

				_REALM.STORE.givingFormStore.state.givingForm.memorials = selectedMemorials;
				return true;
			}

			_REALM.STORE.givingFormStore.state.givingForm.memorials = null;
			return true;
		}
	}
};
</script>

<template>
	<div>
		<r-sortable-list v-model="givingFormState.sortableFunds">
			<template v-slot:itemActions="{ item }">
				<r-actions v-if="item._isChecked" :item="item">
					<r-action-item @click="editFund(item)">Edit Memo</r-action-item>
				</r-actions>
			</template>
		</r-sortable-list>
		<FundForm v-model="showFundFormDialog" :fundProp="fundModel" @updateFund="updateFund"></FundForm>
	</div>
</template>

<script>
import _each from 'lodash/each';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import FundForm from '@/components/giving/givingForms/OnlineFundForm';

export default {
	components: {
		FundForm
	},
	data() {
		return {
			givingFormState: _REALM.STORE.givingFormStore.state,
			fundModel: {},
			showFundFormDialog: false
		};
	},
	created() {
		this.buildSortableFunds();
	},
	methods: {
		editFund(fundModel) {
			this.fundModel = fundModel;
			this.showFundFormDialog = true;
		},
		updateFund(fund) {
			var sortableFund = _find(this.givingFormState.sortableFunds, { fundId: fund.fundId });
			if (sortableFund) {
				sortableFund._isMemoEnabled = fund._isMemoEnabled;
				sortableFund._predefinedMemos = fund._predefinedMemos;
			}
		},
		buildSortableFunds() {
			var self = this;
			_REALM.STORE.fundStore.listOnline({ filterCampusFunds: false }).then(function(funds) {
				var givingFormFunds = _REALM.STORE.givingFormStore.state.givingForm.funds;
				_each(funds, function(fund) {
					var givingFormFund = _find(givingFormFunds, { fundId: fund.fundId });
					if (givingFormFund) {
						self.$set(fund, '_sortBy', _findIndex(givingFormFunds, { fundId: fund.fundId }));
						self.$set(fund, '_isChecked', true);
						self.$set(fund, '_isMemoEnabled', givingFormFund.isMemoEnabled);
						self.$set(fund, '_predefinedMemos', givingFormFund.predefinedMemos && givingFormFund.predefinedMemos.length > 0 ? givingFormFund.predefinedMemos : []);
					} else {
						self.$set(fund, '_sortBy', 9999);
						self.$set(fund, '_isChecked', false);
						self.$set(fund, '_isMemoEnabled', false);
						self.$set(fund, '_predefinedMemos', []);
					}

					//Default all funds check for first giving form
					if (_REALM.STORE.givingFormStore.state.givingForms.length === 0) {
						self.$set(fund, '_isChecked', true);
					}
				});

				self.givingFormState.sortableFunds = _sortBy(funds, '_sortBy');
			});
		}
	}
};
</script>

<style lang="scss">
.design-wrapper,
.donor-preview-dialog-wrapper {
	.preview-embed {
		position: relative;
	}

	.preview-embed::after {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		display: block;
		height: 100%;
		content: ' ';
		background: rgba(0, 0, 0, 0);
	}
}
</style>

<template>
	<v-layout row wrap class="design-wrapper">
		<v-flex lg8 :md7="$vuetify.breakpoint.mdAndUp" :xs12="$vuetify.breakpoint.smAndDown">
			<r-form ref="designForm">
				<r-banner :showBannerOnInit="givingFormState.givingForms.length === 1" class="mb-4">
					<template slot="icon">
						feedback
					</template>
					<template slot="title">
						Customize it to fit you!
					</template>
					<template slot="text">
						Design your giving form to fit the identity of your ministry. If necessary, edit this form's title and subtitle to help your contributors understand what it's for. You can preview your design on the right as you unleash your creativity.
					</template>
				</r-banner>

				<r-card class="mb-4">
					<template slot="title">
						Design
					</template>

					<v-layout row wrap>
						<v-flex xs6>
							<r-text-input v-model="givingFormState.givingForm.title" maxlength="50" label="Title"></r-text-input>
						</v-flex>
						<v-flex xs6>
							<r-text-input v-model="givingFormState.givingForm.subtitle" maxlength="50" label="Subtitle"></r-text-input>
						</v-flex>
						<v-flex xs12 pb-0>
							<r-text-area v-model="givingFormState.givingForm.introMessage" maxlength="2000" label="Intro message"></r-text-area>
						</v-flex>
						<v-flex xs12 pt-0>
							<r-btn class="mt-0 float-right" color="default" @click="intoIdeasDialog = true">Need Ideas?</r-btn>
						</v-flex>

						<v-flex xs12>
							<div class="font-weight-bold">Include a nice photo that represents what this is for.</div>
						</v-flex>
						<v-flex xs12>
							<v-layout justify-start align-end>
								<r-image-crop :image-id="givingFormState.givingForm.formId" :crop-width="200" :crop-height="200" :image-type="imageTypes.GIVING_FORM" :initial-border-radius="logoBorderRadius" crop-type="square" @imageSaved="onLogoSave" @imageDeleted="onLogoDelete"></r-image-crop>
							</v-layout>
						</v-flex>
						<v-flex xs12>
							<div class="font-weight-bold">Select the background color for the header.</div>
						</v-flex>
						<v-flex xs12>
							<r-swatches v-model="givingFormState.givingForm.headerBackgroundColor" />
						</v-flex>
					</v-layout>
				</r-card>

				<r-card>
					<template slot="title">
						Style Options
					</template>

					<v-layout row wrap>
						<v-flex xs12>
							<v-switch v-model="givingFormState.givingForm.useDarkOverlayScheme" label="Use dark mode for frame"></v-switch>
						</v-flex>
						<v-flex xs12>
							<v-switch v-model="givingFormState.givingForm.useDarkBodyScheme" label="Use dark mode for form body"></v-switch>
						</v-flex>

						<v-flex xs12>
							<div class="font-weight-bold">Select an accent color.</div>
						</v-flex>
						<v-flex xs12>
							<r-swatches v-model="givingFormState.givingForm.buttonColor" />
							<r-alert v-if="displayContrastWarning" class="mt-2" type="warning">This accent color might be difficult to see on the current form body. Consider using a {{ givingFormState.givingForm.useDarkBodyScheme ? 'lighter' : 'darker' }} accent color.</r-alert>
						</v-flex>

						<v-flex xs12>
							<div class="font-weight-bold">What style best fits what this form is for?</div>
						</v-flex>
						<v-flex xs12>
							<v-btn-toggle v-model="givingFormState.givingForm.font" mandatory large>
								<v-btn class="modern-font-family" large value="modern">Modern</v-btn>
								<v-btn class="traditional-font-family" large value="traditional">Traditional</v-btn>
								<v-btn class="bold-font-family" large value="bold">Bold</v-btn>
							</v-btn-toggle>
						</v-flex>
					</v-layout>
				</r-card>
			</r-form>
			<r-card v-if="$vuetify.breakpoint.smAndDown" class="mt-4 mb-4">
				<r-btn large block @click="showGivingFormPreviewDialog = true">Preview</r-btn>
			</r-card>
		</v-flex>
		<v-flex v-if="$vuetify.breakpoint.mdAndUp" lg4 md5>
			<div ref="affixWidthRef">
				<Affix :offset="{ top: 80, bottom: 0 }" :style="{ width: affixWidth }" relative-element-selector=".design-wrapper">
					<DonorFormPreview class="preview-embed not-selectable not-allowed-cursor" form-type="Giving"></DonorFormPreview>
				</Affix>
			</div>
		</v-flex>

		<r-dialog v-model="showGivingFormPreviewDialog" max-width="600" scrollable max-height="700">
			<div class="donor-preview-dialog-wrapper">
				<DonorFormPreview class="preview-embed not-selectable not-allowed-cursor" form-type="Giving"></DonorFormPreview>
			</div>
			<template slot="actions">
				<v-spacer></v-spacer>
				<r-btn color="default" @click="showGivingFormPreviewDialog = false">Close</r-btn>
			</template>
		</r-dialog>

		<r-dialog v-model="intoIdeasDialog" max-width="700" scrollable max-height="700" type="primary">
			<template slot="title">
				Need some inspiration for your message?
			</template>
			<p>
				Your intro message lets your contributors know what they're giving to. This is a great place to tell them what an impact they'll make. Below are short, generic messages to start you off that you can personalize to suit your need.
			</p>
			<r-card v-for="(idea, index) in introIdeas" :key="index" text color="grey" class="mt-4">
				<v-layout align-center justify-center row fill-height>
					<v-flex class="font-weight-medium font-italic" xs10>{{ idea }}</v-flex>
					<v-flex class="text-right" xs2>
						<r-btn depressed round @click="setMessage(idea)">Use</r-btn>
					</v-flex>
				</v-layout>
			</r-card>

			<template slot="actions">
				<v-spacer></v-spacer>
				<r-btn color="default" @click="intoIdeasDialog = false">Close</r-btn>
			</template>
		</r-dialog>
	</v-layout>
</template>

<script>
import { Affix } from 'vue-affix';
import DonorFormPreview from '@/components/giving/sharedForms/DonorFormPreview.vue';

export default {
	components: {
		DonorFormPreview,
		Affix
	},
	data() {
		return {
			givingFormState: _REALM.STORE.givingFormStore.state,
			layoutStore: _REALM.STORE.layoutStore,
			affixWidth: null,
			intoIdeasDialog: false,
			showGivingFormPreviewDialog: false,
			imageTypes: _REALM.STORE.mediaStore.ImageTypes,
			introIdeas: [
				'Giving is a blessing that changes lives for the better.',
				'Your support will bring relief and restoration to disaster victims.',
				'Tithing is the foundation of giving.',
				'As our ministry reaches out to the world, our missionaries need your help.',
				'Our renovation is underway, and your donations will help us finish strong!'
			]
		};
	},
	computed: {
		givingFormUrl() {
			return _REALM.STORE.givingFormStore.getGivingFormBaseUrl() + this.givingFormState.givingForm.urlPath + '?integrationType=2';
		},
		logoBorderRadius() {
			return _REALM.STORE.givingFormStore.getLogoBorderRadius();
		},
		displayContrastWarning() {
			return !this.layoutStore.accentContrastIsValid(this.givingFormState.givingForm.buttonColor, this.givingFormState.givingForm.useDarkBodyScheme);
		}
	},
	mounted() {
		this.watchEmbedPreviewWidth();
	},
	destroyed() {
		window.removeEventListener('resize', this.setPreviewWidth);
	},
	methods: {
		validate() {
			return this.$refs.designForm.validate();
		},
		watchEmbedPreviewWidth() {
			this.setPreviewWidth();
			window.addEventListener('resize', this.setPreviewWidth);
		},
		setPreviewWidth() {
			if (this.$refs && this.$refs.affixWidthRef && this.$refs.affixWidthRef.clientWidth) {
				this.affixWidth = this.$refs.affixWidthRef.clientWidth + 'px';
			}
		},
		setMessage(message) {
			this.givingFormState.givingForm.introMessage = message;
			this.intoIdeasDialog = false;
		},
		onLogoSave(data) {
			this.givingFormState.givingForm.logoBorderRadius = parseFloat(data.borderRadius);
		},
		onLogoDelete() {
			this.givingFormState.givingForm.logoBorderRadius = 50;
		}
	}
};
</script>

<style lang="scss">
.general-use-wrapper {
	.color-swatch {
		display: inline-block;
		width: 20px;
		height: 20px;
		vertical-align: bottom;
	}
}
</style>

<template>
	<v-layout wrap class="general-use-wrapper">
		<v-flex md8 order-sm2 order-xs2 order-md1>
			<r-card class="mb-4">
				<template slot="title">
					Share a direct link to the giving form.
				</template>
				<v-layout>
					<r-text-input :value="givingFormUrl" filled readonly></r-text-input>
					<r-btn class="ml-3 mt-3" type="button" @click="copyToClipboard(givingFormUrl)">
						Copy
					</r-btn>
				</v-layout>
			</r-card>

			<r-card class="mb-4">
				<template slot="title">
					Embed this giving form on your website.
				</template>
				<p>Copy and paste the following code as the last item in the &lt;BODY&gt; of the webpage. <r-help-link resource-id="Realm.GivingForm.Embed">Learn more.</r-help-link></p>
				<v-layout>
					<r-text-input :value="embedScript" filled readonly></r-text-input>
					<r-btn class="ml-3 mt-3" type="button" @click="copyToClipboard(embedScript)">
						Copy
					</r-btn>
				</v-layout>
				<p>Include this link on the page you'd like to use to trigger the giving form.</p>
				<v-layout>
					<r-text-input :value="givingFormUrl" filled readonly></r-text-input>
					<r-btn class="ml-3  mt-3" type="button" @click="copyToClipboard(givingFormUrl)">
						Copy
					</r-btn>
				</v-layout>
			</r-card>

			<r-card v-if="givingState.settings.isTextGivingEnabled && (givingFormState.givingForm.isText || givingFormState.givingForm.textKeyword)" class="mb-4">
				<template slot="title">
					Share your text giving keyword.
				</template>
				<p>
					Create a recommended announcement for your website, bulletin, or projector to tell donors how to give through text.
				</p>

				Included are the following...
				<ul class="mb-3">
					<li>
						Privacy Policy - https://legal.acst.com/privacy-policy
					</li>
					<li>
						Terms of Service - https://legal.acst.com/terms-service-use
					</li>
				</ul>
				<v-layout>
					<r-text-input :value="textGivingCopy" filled readonly></r-text-input>
					<r-btn class="ml-3 mt-3" type="button" @click="copyToClipboard(textGivingCopy)">
						Copy
					</r-btn>
				</v-layout>
			</r-card>
		</v-flex>

		<v-flex md4 order-sm1 order-xs1 order-md2>
			<r-card>
				<template slot="title">
					General Settings
				</template>
				<template slot="titleActions">
					<r-btn v-if="!givingFormState.givingForm.dateDeactivated" :to="{ name: 'giving-form', params: { page: 'general-settings', formId: givingFormState.givingForm.formId } }" class="pull-right" small color="default">
						Edit
					</r-btn>
				</template>

				<v-layout row wrap>
					<v-flex xs12>
						<hr class="primary" />
					</v-flex>
					<v-flex md4 xs12 class="font-weight-bold">Giving form name</v-flex>
					<v-flex md8 xs12>{{ givingFormState.givingForm.name }}</v-flex>

					<v-flex md4 xs12 class="font-weight-bold">Suggested gift</v-flex>
					<v-flex md8 xs12>{{ givingFormState.givingForm.defaultAmount | centsToDollars }}</v-flex>

					<v-flex md4 xs12 class="font-weight-bold">Unique URL</v-flex>
					<v-flex md8 xs12 class="break-word">{{ givingFormUrl }}</v-flex>
					<template v-if="givingState.settings.isTextGivingEnabled">
						<v-flex v-if="givingFormState.givingForm.isText || givingFormState.givingForm.textKeyword" md4 xs12 class="font-weight-bold">Text keyword</v-flex>
						<v-flex v-if="givingFormState.givingForm.isText" md8 xs12>{{ givingState.settings.primaryKeyword }}</v-flex>
						<v-flex v-if="givingFormState.givingForm.textKeyword" md8 xs12>{{ givingFormState.givingForm.textKeyword }}</v-flex>
					</template>
					<v-flex md4 xs12 class="font-weight-bold">Selected funds</v-flex>
					<v-flex v-if="fundsLoaded" md8 xs12 class="break-word">{{ selectedFunds | nameList }}</v-flex>
					<v-flex v-if="givingFormStore.isMemorialEnabled()" md4 xs12 class="font-weight-bold">Selected In Memory / In Honor</v-flex>
					<v-flex v-if="givingFormStore.isMemorialEnabled()" md8 xs12 class="break-word">{{ selectedMemorials | nameList }}</v-flex>
				</v-layout>
			</r-card>

			<r-card class="mt-4">
				<template slot="title">
					Design
				</template>
				<template slot="titleActions">
					<r-btn v-if="!givingFormState.givingForm.dateDeactivated" :to="{ name: 'giving-form', params: { page: 'design', formId: givingFormState.givingForm.formId } }" class="pull-right" small color="default">
						Edit
					</r-btn>
				</template>

				<v-layout row wrap>
					<v-flex xs12>
						<hr class="primary" />
					</v-flex>
					<v-flex md4 xs12 class="font-weight-bold">Title</v-flex>
					<v-flex md8 xs12>{{ givingFormState.givingForm.title }}</v-flex>

					<v-flex md4 xs12 class="font-weight-bold">Subtitle</v-flex>
					<v-flex md8 xs12>{{ givingFormState.givingForm.subtitle }}</v-flex>

					<v-flex md4 xs12 class="font-weight-bold">Intro message</v-flex>
					<v-flex md8 xs12 class="break-word">{{ givingFormState.givingForm.introMessage }}</v-flex>

					<v-flex md4 xs12 class="font-weight-bold">Photo</v-flex>
					<v-flex md8 xs12>
						<r-media-image :image-id="givingFormState.givingForm.formId" :width="40" :image-type="imageTypes.GIVING_FORM" :border-radius="logoBorderRadius"></r-media-image>
					</v-flex>

					<v-flex md4 xs12 class="font-weight-bold">Header color</v-flex>
					<v-flex md8 xs12>
						<div :style="{ backgroundColor: givingFormState.givingForm.headerBackgroundColor }" class="color-swatch"></div>
						{{ givingFormState.givingForm.headerBackgroundColor }}
					</v-flex>

					<v-flex md4 xs12 class="font-weight-bold">Dark overlay</v-flex>
					<v-flex md8 xs12>{{ givingFormState.givingForm.useDarkOverlayScheme | yesNo }}</v-flex>

					<v-flex md4 xs12 class="font-weight-bold">Dark form body</v-flex>
					<v-flex md8 xs12>{{ givingFormState.givingForm.useDarkBodyScheme | yesNo }}</v-flex>

					<v-flex md4 xs12 class="font-weight-bold">Action color</v-flex>
					<v-flex md8 xs12>
						<div :style="{ backgroundColor: givingFormState.givingForm.buttonColor }" class="color-swatch"></div>
						{{ givingFormState.givingForm.buttonColor }}
					</v-flex>

					<v-flex md4 xs12 class="font-weight-bold">Style</v-flex>
					<v-flex md8 xs12>{{ givingFormState.givingForm.font | capitalizeFirstLetter }}</v-flex>
				</v-layout>
			</r-card>

			<r-card class="mt-4">
				<template slot="title">
					Thank You Message
				</template>
				<template slot="titleActions">
					<r-btn v-if="!givingFormState.givingForm.dateDeactivated" :to="{ name: 'giving-form', params: { page: 'thank-you-message', formId: givingFormState.givingForm.formId } }" class="pull-right" small color="default">
						Edit
					</r-btn>
				</template>

				<v-layout row wrap>
					<v-flex xs12>
						<hr class="primary" />
					</v-flex>
					<v-flex md4 xs12 class="font-weight-bold">Message</v-flex>
					<v-flex md8 xs12>{{ givingFormState.givingForm.receiptMessage }}</v-flex>
					<v-flex v-if="showAddPledgeOption" md4 xs12 class="font-weight-bold">Add Pledge Option</v-flex>
					<v-flex v-if="showAddPledgeOption" md8 xs12>{{ givingFormState.givingForm.showAddPledge | yesNo }}</v-flex>
				</v-layout>
			</r-card>
		</v-flex>
	</v-layout>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import { formatCentsToDollars } from '@/helpers/formatters';
export default {
	filters: {
		nameList(itemsWithNames) {
			const names = itemsWithNames.map(i => i.name).slice(0, 15);
			let nameString = names.join(', ');
			if (itemsWithNames.length > names.length) {
				nameString += ` and ${itemsWithNames.length - names.length} more`;
			}

			return nameString;
		}
	},
	data() {
		return {
			fundsLoaded: false,
			givingState: _REALM.STORE.givingStore.state,
			siteState: _REALM.STORE.siteStore.state,
			fundState: _REALM.STORE.fundStore.state,
			showAddPledgeOption: false,
			givingFormState: _REALM.STORE.givingFormStore.state,
			givingFormStore: _REALM.STORE.givingFormStore,
			memorialStore: _REALM.STORE.memorialStore,
			imageTypes: _REALM.STORE.mediaStore.ImageTypes,
			givingFormActivateId: 'r-activate-form', //Do not change this value unless the giving-embed.js is changed to match
			givingFormAttributeId: 'r-form-id-', //Do not change this value unless the giving-embed.js is changed to match
			givingFormAttributeSiteId: 'r-site-id-' //Do not change this value unless the giving-embed.js is changed to match
		};
	},
	computed: {
		givingFormUrl() {
			return _REALM.STORE.givingFormStore.getGivingFormBaseUrl() + this.givingFormState.givingForm.urlPath;
		},
		embedScript() {
			return `<script id="r-embed-script"><\/script><script type="text/javascript">document.getElementById('r-embed-script').src = '${_REALM.CONFIG.StratusHost}/Assets/Embed/giving-embed.js?v=' + Date.now();<\/script>`; // eslint-disable-line no-useless-escape
		},
		selectedFunds() {
			var givingFormFunds = _cloneDeep(this.givingFormState.givingForm.funds);

			var fundIds = _map(givingFormFunds, 'fundId');

			return _filter(this.fundState.onlineFunds, function(fund) {
				return fundIds.includes(fund.fundId);
			});
		},
		selectedMemorials() {
			const selectedMemorials = [];
			for (const selectableMemorial of this.memorialStore.state.selectableGivingFormMemorials) {
				const memorial = this.memorialStore.state.activeMemorials.find(m => m.memorialId === selectableMemorial.value);
				if (memorial) {
					selectedMemorials.push(memorial);
				}
			}

			return selectedMemorials;
		},
		textGivingCopy() {
			return `Text "${this.givingState.settings.primaryKeyword + (this.givingFormState.givingForm.textKeyword ? ' ' + this.givingFormState.givingForm.textKeyword : '')}${
				this.givingFormState.givingForm.defaultAmount ? formatCentsToDollars(this.givingFormState.givingForm.defaultAmount, { addSymbol: true }) : ''
			}" to 73256 to give using your mobile device. This number will never send unsolicited texts to you. To cancel further messages, text STOP. If you need assistance with text giving, text HELP. Standard text message and data rates may apply. Privacy Policy - https://legal.acst.com/privacy-policy Terms of Service - https://legal.acst.com/terms-service-use`;
		},
		logoBorderRadius() {
			return _REALM.STORE.givingFormStore.getLogoBorderRadius();
		}
	},
	created() {
		this.loadFunds();
		this.listActiveCampaigns();
	},
	methods: {
		listActiveCampaigns() {
			var self = this;
			_REALM.STORE.campaignStore.listActiveCampaigns().then(function(campaigns) {
				var campaginFundIds = campaigns.map(a => a.fundId);
				var givingFormFundIds = self.givingFormState.givingForm.funds.map(a => a.fundId);
				self.showAddPledgeOption = campaginFundIds.some(item => givingFormFundIds.includes(item));
			});
		},
		copyToClipboard(value) {
			_REALM.TOOLS.copyToClipboard(value);
		},
		loadFunds() {
			var self = this;
			_REALM.STORE.fundStore.listOnline({ filterCampusFunds: false }).then(function() {
				self.fundsLoaded = true;
			});
		}
	}
};
</script>

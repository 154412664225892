<template>
	<v-layout>
		<v-flex xs12>
			<r-breadcrumbs :links="links"></r-breadcrumbs>
			<v-divider></v-divider>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	computed: {
		links() {
			var links = [
				{
					name: 'giving-forms',
					text: 'Giving Forms',
					exact: true
				}
			];

			if (_REALM.VM.$route.params.page == 'general-use') {
				links.push({
					text: _REALM.STORE.givingFormStore.state.givingForm.name
				});
			}

			if (_REALM.VM.$route.params.page != 'general-use') {
				links.push({
					name: 'giving-form',
					text: _REALM.STORE.givingFormStore.state.givingForm.name,
					exact: true,
					params: {
						page: 'general-use',
						formId: _REALM.STORE.givingFormStore.state.givingForm.formId
					}
				});

				if (_REALM.VM.$route.params.page == 'design') {
					links.push({
						text: 'Design'
					});
				}

				if (_REALM.VM.$route.params.page == 'general-settings') {
					links.push({
						text: 'General Settings'
					});
				}

				if (_REALM.VM.$route.params.page == 'thank-you-message') {
					links.push({
						text: 'Thank You Message'
					});
				}
			}

			return links;
		}
	}
};
</script>

<template>
	<r-dialog :value="value" width="700" max-width="700px" @input="$emit('input', $event)">
		<template slot="title"> Edit {{ fund.name }} </template>
		<r-form :id="_uid" ref="onlineFundForm" :submitMethod="saveFund">
			<v-layout wrap>
				<v-flex xs12>
					<v-radio-group v-model="memoOptionValue">
						<v-radio :value="1" label="Don't allow memos."></v-radio>
						<v-radio :value="2" label="Allow your contributors to type their own memos."></v-radio>
						<v-radio :value="3" label="Allow your contributors to select from a list of predefined memos."></v-radio>
					</v-radio-group>
				</v-flex>

				<v-flex xs12>
					<v-combobox v-if="memoOptionValue === 3" v-model="fund._predefinedMemos" :rules="predefinedMemoRules" append-icon chips dense multiple label="Add some predefined memos">
						<template slot="selection" slot-scope="{ item, parent }">
							<v-chip>
								{{ item }}
								<v-icon small @click="parent.selectItem(item)">close</v-icon>
							</v-chip>
						</template>
					</v-combobox>
				</v-flex>
			</v-layout>
		</r-form>
		<template slot="actions">
			<v-spacer></v-spacer>
			<r-btn color="default" @click="closeDialog">Cancel</r-btn>
			<r-btn :form="_uid" type="submit">Save</r-btn>
		</template>
	</r-dialog>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';

export default {
	props: {
		value: Boolean,
		fundProp: {
			type: Object
		}
	},
	data() {
		return {
			fund: {},
			memoOptionValue: 1,
			predefinedMemoRules: [
				function(v) {
					if (v) {
						for (var i = 0; i < v.length; i++) {
							if (v[i] && v[i].length > 40) {
								return 'Predefined memos must be less than 40 characters';
							}
						}
					}

					return true;
				},

				v => (v && v.toString().length > 0) || `Predefined memo is required`
			]
		};
	},
	watch: {
		value(val) {
			if (val) {
				this.editFund();
			} else {
				this.fund = {};
			}
		}
	},
	methods: {
		editFund() {
			this.fund = _cloneDeep(this.fundProp);

			if (!this.fund._isMemoEnabled) {
				this.memoOptionValue = 1;
			} else if (this.fund._isMemoEnabled && this.fund._predefinedMemos.length === 0) {
				this.memoOptionValue = 2;
			} else {
				this.memoOptionValue = 3;
			}
		},
		closeDialog() {
			this.fund = {};
			this.$emit('input', false);
		},
		saveFund() {
			if (this.memoOptionValue === 1 || this.memoOptionValue === 2) {
				this.fund._predefinedMemos = [];
			}

			this.fund._isMemoEnabled = this.memoOptionValue !== 1;

			if (this.$refs.onlineFundForm.validate()) {
				this.$emit('updateFund', this.fund);
				this.closeDialog();
			}
		}
	}
};
</script>

<template>
	<v-layout row wrap>
		<v-flex xs12>
			<r-banner :showBannerOnInit="givingFormState.givingForms.length === 1" class="mb-4">
				<template slot="icon">
					feedback
				</template>
				<template slot="title">
					What would you like to tell your contributors?
				</template>
				<template slot="text">
					Personalize a message for your contributors to see after they give. A thank you message is a great way to express appreciation to your contributors.
				</template>
			</r-banner>

			<r-card class="mb-4">
				<template slot="title">
					Thank You Message
				</template>
				<v-layout row wrap>
					<v-flex xs12>
						<r-form ref="thankYouMessageForm">
							<r-text-area v-model="givingFormState.givingForm.receiptMessage" maxlength="2000" label="Message" required placeholder="This appreciation message will display to the contributor after they complete a gift"></r-text-area>
							<v-flex xs12 class="float-right">
								<r-btn class="mt-0" color="default" @click="suggestionsDialog = true">Need Ideas?</r-btn>
							</v-flex>
						</r-form>
					</v-flex>
					<v-flex v-if="showAddPledgeOption" xs12>
						<v-switch v-model="givingFormState.givingForm.showAddPledge">
							<template v-slot:label>
								Allow contributors to add a pledge with a recurring gift
								<r-info-tooltip>This option is only available if the contributor is signed in</r-info-tooltip>
							</template>
						</v-switch>
					</v-flex>
				</v-layout>
			</r-card>
		</v-flex>

		<r-dialog v-model="suggestionsDialog" max-width="700" scrollable max-height="500" type="primary">
			<template slot="title">
				A little thanks goes a long way.
			</template>
			<p>
				Your thank you message can range from a simple show of appreciation to a full acknowledgement of the impact your contributors have made. Below are short, generic messages to start you off that you can personalize to suit your need.
			</p>
			<r-card v-for="(message, index) in messageSuggestions" :key="index" text color="grey" class="mt-4">
				<v-layout align-center justify-center row fill-height>
					<v-flex class="font-weight-medium font-italic" xs10>{{ message }}</v-flex>
					<v-flex class="text-right" xs2>
						<r-btn depressed round @click="setMessage(message)">Use</r-btn>
					</v-flex>
				</v-layout>
			</r-card>
			<template slot="actions">
				<v-spacer></v-spacer>
				<r-btn color="default" @click="suggestionsDialog = false">Close</r-btn>
			</template>
		</r-dialog>
	</v-layout>
</template>

<script>
export default {
	data() {
		return {
			givingFormState: _REALM.STORE.givingFormStore.state,
			suggestionsDialog: false,
			showAddPledgeOption: false,
			messageSuggestions: ["Thank you for supporting our ministries and God's work.", 'We are humbled by your gift toward strengthening our ministry.', "With your generosity, we're closer to reaching our goal!"]
		};
	},
	created() {
		this.listActiveCampaigns();
	},
	methods: {
		listActiveCampaigns() {
			var self = this;
			_REALM.STORE.campaignStore.listActiveCampaigns().then(function(campaigns) {
				var campaginFundIds = campaigns.map(a => a.fundId);
				var givingFormFundIds = self.givingFormState.givingForm.funds.map(a => a.fundId);
				self.showAddPledgeOption = campaginFundIds.some(item => givingFormFundIds.includes(item));
			});
		},
		validate() {
			return this.$refs.thankYouMessageForm.validate();
		},
		setMessage(message) {
			this.givingFormState.givingForm.receiptMessage = message;
			this.suggestionsDialog = false;
		}
	}
};
</script>
